<template>
	<main class="relative flex flex-col min-h-screen lg:flex-row">
		<div class="sidebar bg-black p-8 border-blue lg:border-l-14 lg:min-w-[250px] lg:w-1/5 lg:py-14 lg:px-8">
			<div class="flex items-center justify-between">
				<a class="" href="/">
					<img class="logo" :src="logoPath" width="177" height="42" alt="ZeroFox" />
				</a>
				<hamburger></hamburger>
			</div>
			<navigation></navigation>
		</div>
		<div class="page relative flex-1">
			<router-view />
		</div>
		<div
			class="pagination flex items-center border-t-1 border-gray lg:absolute lg:w-4/5 lg:bottom-0 lg:right-0"
			:class="{ 'justify-end': forward }"
		>
			<template v-if="back">
				<router-link
					class="prev group flex items-center justify-end gap-4"
					:class="{ 'border-r-1 border-gray': !forward }"
					:to="prevRoutePath"
					rel="noopener"
				>
					<svg
						class="-rotate-180 group-hover:-translate-x-2 transition duration-500 ease-in-out"
						xmlns="http://www.w3.org/2000/svg"
						width="37"
						height="37"
						viewBox="0 0 37 37"
					>
						<circle
							cx="18.5"
							cy="18.5"
							r="17.5"
							class="stroke-2 stroke-cyber-blue fill-cyber-blue group-hover:fill-black group-hover:stroke-black transition duration-500 ease-in-out"
						></circle>
						<path
							d="M19.62,23.54l5.04-5.04-5.04-5.04m-7.28,10.08l5.04-5.04-5.04-5.04"
							class="fill-transparent stroke-2 stroke-blue stroke-round group-hover:stroke-cyber-blue transition duration-500 ease-in-out"
						></path>
					</svg>
					<span>{{ prevRouteName }}</span>
				</router-link>
			</template>
			<template v-if="forward">
				<router-link
					class="next group flex items-center gap-4"
					:class="{ 'border-l-1 border-gray': forward }"
					:to="nextRoutePath"
					rel="noopener"
				>
					<span>{{ nextRouteName }}</span>
					<svg
						class="group-hover:translate-x-2 transition duration-500 ease-in-out"
						xmlns="http://www.w3.org/2000/svg"
						width="37"
						height="37"
						viewBox="0 0 37 37"
					>
						<circle
							cx="18.5"
							cy="18.5"
							r="17.5"
							class="stroke-2 stroke-cyber-blue fill-cyber-blue group-hover:fill-black group-hover:stroke-black transition duration-500 ease-in-out"
						></circle>
						<path
							d="M19.62,23.54l5.04-5.04-5.04-5.04m-7.28,10.08l5.04-5.04-5.04-5.04"
							class="fill-transparent stroke-2 stroke-blue stroke-round group-hover:stroke-cyber-blue transition duration-500 ease-in-out"
						></path>
					</svg>
				</router-link>
			</template>
		</div>
	</main>
</template>

<script>
import Hamburger from '@/components/Hamburger';
import Navigation from '@/components/Nav';

export default {
	name: 'App',
	components: {
		Hamburger,
		Navigation
	},
	data() {
		return {
			publicPath: process.env.BASE_URL,
			routes: this.$router.options.routes,
			currentRouteId: 0,
			nextRouteName: null,
			nextRoutePath: null,
			prevRouteName: null,
			back: false,
			forward: false,
			logoPath: '/wp-content/themes/brand-guide/app/logo.svg'
		};
	},
	mounted() {
		// Init currentRouteId from URL
		this.refreshCurrentRouteId(this.$router.currentRoute.value.fullPath);

		// Handle manual page change
		this.$router.beforeEach((to, from, next) => {
			next();
		});

		this.$router.afterEach((to) => {
			this.refreshCurrentRouteId(to.path);
		});
	},
	methods: {
		refreshCurrentRouteId(currentPath) {
			// Pagination Logic
			this.currentRouteId = this.routes.findIndex((route) => route.path === currentPath);

			let next = this.currentRouteId + 1 === this.routes.length ? this.routes.length : this.currentRouteId + 1;
			let prev = this.currentRouteId === 0 ? 0 : this.currentRouteId - 1;

			// Next Button
			if (this.currentRouteId + 2 === this.routes.length) {
				this.forward = false;
			} else {
				this.forward = true;
				this.nextRouteName = this.routes[next].name;
				this.nextRoutePath = this.routes[next].path;
			}

			// Previous Button
			if (this.currentRouteId === 0) {
				this.back = false;
			} else {
				this.back = true;
				this.prevRouteName = this.routes[prev].name;
				this.prevRoutePath = this.routes[prev].path;
			}
		}
	}
};
</script>
<style src="./assets/css/style.css" lang="css"></style>
