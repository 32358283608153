<template>
	<button class="relative w-10 h-10 focus:outline-none lg:hidden" @click="toggleActive">
		<span class="sr-only">Open main menu</span>
		<div class="block w-8 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-cyber-blue">
			<span
				aria-hidden="true"
				class="bar block absolute h-[3px] w-8 bg-current transform transition-all duration-300 ease-in-out rounded"
				:class="{ 'rotate-[-225deg]': isOpen, ' -translate-y-2': !isOpen }"
			></span>
			<span
				aria-hidden="true"
				class="bar block absolute h-[3px] w-8 bg-current transform transition-all duration-300 ease-in-out rounded"
				:class="{ 'opacity-0 rotate-180': isOpen }"
			></span>
			<span
				aria-hidden="true"
				class="bar block absolute h-[3px] w-8 bg-current transform transition-all duration-300 ease-in-out rounded"
				:class="{ 'rotate-[-135deg]': isOpen, ' translate-y-2': !isOpen }"
			></span>
		</div>
	</button>
</template>

<script>
export default {
	name: 'Hamburger',
	data() {
		return {
			isOpen: false
		};
	},
	mounted() {},
	methods: {
		toggleActive() {
			// this.$emit('close-pop-up');
			this.isOpen = !this.isOpen;

			document.querySelector('.sidebar').classList.toggle('is-open');
		}
	}
};
</script>
