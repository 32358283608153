<template>
	<div id="nav" class="nav w-full space-y-8 ml-2 translate-y-8 opacity-0 invisible lg:opacity-100 lg:visible lg:mt-8">
		<template v-for="(route, index) in mainNav" :key="index">
			<router-link class="nav-item text-white" :to="route.path" rel="noopener">
				{{ route.name }}
			</router-link>
		</template>
	</div>
</template>

<script>
export default {
	name: 'Navigation',
	data() {
		return {
			publicPath: process.env.BASE_URL,
			routes: this.$router.options.routes,
			currentRouteId: 0,
			nextRouteName: null,
			nextRoutePath: null,
			prevRouteName: null,
			mainNav: []
		};
	},
	created() {
		this.$router.options.routes.forEach((route) => {
			if (route.props.mainNav === true) {
				this.mainNav.push({
					name: route.name,
					path: route.path
				});
			}
			if (route.props.mainNav !== true && route.name !== '404') {
				this.subNav.push({
					name: route.name,
					path: route.path
				});
			}
		});
	}
};
</script>
