import { createWebHistory, createRouter } from 'vue-router';

import Home from '@/views/Home.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		props: { mainNav: true }
	},
	{
		path: '/logo',
		name: 'Logo',
		props: { mainNav: true },
		component: () => import('@/views/Logo')
	},
	{
		path: '/color',
		name: 'Color',
		props: { mainNav: true },
		component: () => import('@/views/Color')
	},
	{
		path: '/type',
		name: 'Typography',
		props: { mainNav: true },
		component: () => import('@/views/Typography')
	},
	{
		path: '/photography',
		name: 'Photography',
		props: { mainNav: true },
		component: () => import('@/views/Photography')
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		props: { mainNav: false },
		component: () => import('@/views/NotFound')
	}
	// {
	// 	path: '/:catchAll(.*)',
	// 	name: '404',
	// 	component: NotFound,
	// 	props: { mainNav: false }
	// }
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
